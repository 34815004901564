.center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.title {
    color: white;
    font-size: 80px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    padding: 0;
    margin: 0;
}

.subtitle {
    color: white;
    font-size: 27.5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    padding: 0;
    margin: 0;
}

.home-social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.home-social-image {
    width: 37.5px;
    height: 37.5px;
    margin-inline: 20px;
    cursor: pointer;
}