.link {
    width: fit-content;
    margin-inline: 10px;
    padding-inline: 15px;
    padding-block: 10px;
    border-radius: 10px;
}

.link:hover {
    background-color: #8fd8ff;
}