.app-row {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.app-image {
    width: 200px;
    height: 200px;
    border-radius: 25px;

    border-color: #41b4f2;
    border-width: 1px;
    border-style: solid;

    transition: 500ms;
}

.app-image:hover {
    border-radius: 50px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
}

@media screen and (max-width: 550px) {
    .app-row {
        display: block;
        margin-top: 0px;
    }
    .app-image {
        margin-top: 30px
    }
}