.about-info {
    display: flex;
    justify-content: space-evenly;
    margin-top: 40px;
}

.about-info-item {
    margin-block: 0px;
}


.about-info-image {
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
}

.about-info-logo {
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
}

.about-info-link {
    color: black;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 425px) {
    .about-info {
        display: block;
    }
    .about-info-item {
        margin-block: 40px;
    }
}