nav {
    width: 100vw;
    height: 100px;
    display: flex;
    align-items: center;
}

.nav-main {
    width: 100%;
    max-width: 1400px;
    height: 80%;
    margin-inline: auto;
    padding-inline: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-items {
    display: flex;
    align-items: center;
}

.nav-items-links {
    font-weight: 800;
    margin-inline: 10px;
    padding-inline: 15px;
    padding-block: 10px;
    border-radius: 10px;
}

.nav-items-links:hover {
    background-color: #8fd8ff;
}

.nav-image {
    height: 100%;
    width: auto;
    object-fit: contain;
}

@media screen and (max-width: 735px) {
    nav {
        height: 70px;
    }
    .nav-main {
        padding-inline: 10px;
    }
}

.nav-icons {
    margin-inline: 10px;
    cursor: pointer;
}

.nav-menu-links {
    font-weight: 800;
    margin: 0px 15px;
    padding-block: 10px;
}

.nav-menu {
    width: 100%;
    text-align: right;
    margin: 0px;
    padding: 0px;
    z-index: 100;
    animation: nav-menu-animation 500ms linear forwards;
}

.nav-menu-disapper {
    animation: nav-menu-disapper-animation 500ms linear forwards;
    margin: 0px;
    padding: 0px;
}

@keyframes nav-menu-animation {
    from {
        transform: translateX(200px);
        height: 0px;
    } to {
        transform: translateX(0px);
        height: 140px;
    }
}

@keyframes nav-menu-disapper-animation {
    from {
        transform: translateX(0px);
        height: 140px;
    } to {
        transform: translateX(200px);

        height: 0px;
    }
}