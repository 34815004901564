.tutorial-list {
    margin-top: 20px;
}

.tutorial-main {
    width: 95%;
    margin: 20px auto;
    padding: 10px 25px;
    background: rgba( 255, 255, 255, 0 );
    box-shadow: 0 8px 32px 0 rgba(36, 44, 134, 0.3);
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
}

.tutorial-main:hover {
    background: rgba( 255, 255, 255, 0.1 );
}

@media screen and (max-width: 1200px) {
    .tutorial-main {
        width: 90%;
        margin-block: 5%;
    }
}