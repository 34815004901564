.app-page-download {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app-page-store-image {
    height: 50px;
    object-fit: contain;
    margin-inline: 20px
}

.app-page-screenshots {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
}

.app-page-images {
    flex: 1;
    width: 30%;
    margin-inline: 10px;
    margin-block: 0px;
    object-fit: contain;
}

.link {
    width: fit-content;
    margin-inline: 10px;
    padding-inline: 15px;
    padding-block: 10px;
    border-radius: 10px;
}

.link:hover {
    background-color: #8fd8ff;
}

@media screen and (max-width: 650px) {
    .app-page-download {
        justify-content: center;
    }
}

@media screen and (max-width: 405px) {
    .app-page-download {
        flex-direction: column;
        margin-top: -10px;
    }
    .app-page-store-image {
        margin-block: 10px;
    }
}

@media screen and (max-width: 700px) {
    .app-page-screenshots {
        flex-direction: column;
    }
    .app-page-images {
        max-width: 450px;
        width: 90%;
        margin-block: 10px;
    }
}