body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #41b4f2;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  width: 100%;
  max-width: 1100px;
  margin-inline: auto;
  padding-top: 0px;
}

.page-title {
  font-weight: 800;
  padding-bottom: 20px;
}

.text {
  margin-inline: 10px;
  font-size: large;
  font-family: 'Open Sans', 'Helvetica Neue';
  text-align: justify;
}